// $base03:    #002b36;
// $base02:    #073642;
// $base01:    #586e75;
// $base00:    #657b83;
// $base0:     #839496;
// $base0A:     #93a1a1;
// $base2:     #eee8d5;
// $base3:     #fdf6e3;
// $yellow:    #b58900;
// $orange:    #cb4b16;
// $red:       #dc322f;
// $magenta:   #d33682;
// $violet:    #6c71c4;
// $blue:      #268bd2;
// $cyan:      #2aa198;
// $green:     #859900;



$base00: #2b303b;
$base01: #343d46;
$base02: #4f5b66;
$base03: #65737e;
$base04: #a7adba;
$base05: #c0c5ce;
$base06: #dfe1e8;
$base07: #eff1f5;

$base08: #bf616a;
$base09: #d08770;
$base0A: #ebcb8b;
$base0B: #a3be8c;
$base0C: #96b5b4;
$base0D: #8fa1b3;
$base0E: #b48ead;
$base0F: #ab7967;

$red: $base08;
$orange: $base09;
$yellow: $base0A;
$green: $base0B;
$cyan: $base0C;
$blue: $base0D;
$purple: $base0E;
$brown: $base0F;


pre {
  background-color: transparent;
  border: 1px solid transparent;
  code a {
    &:not(.image), &:active, &:visited {
      color: $base0A;
    }
    &:hover {
      color: lighten($base0A, 20% );
    }
  }
}
.highlight {
  .lineno { color: $base01 }; // Line Numbers
  .c { color: $base01 } /* Comment */
  .err { color: $base0A } /* Error */
  .g { color: $base0A } /* Generic */
  .k { color: $green } /* Keyword */
  .l { color: $base0A } /* Literal */
  .n { color: $base0A } /* Name */
  .o { color: $green } /* Operator */
  .x { color: $orange } /* Other */
  .p { color: $base0A } /* Punctuation */
  .cm { color: $base01 } /* Comment.Multiline */
  .cp { color: $green } /* Comment.Preproc */
  .c1 { color: $base01 } /* Comment.Single */
  .cs { color: $green } /* Comment.Special */
  .gd { color: $cyan } /* Generic.Deleted */
  .ge { color: $base0A; font-style: italic } /* Generic.Emph */
  .gr { color: $red } /* Generic.Error */
  .gh { color: $orange } /* Generic.Heading */
  .gi { color: $green } /* Generic.Inserted */
  .go { color: $base0A } /* Generic.Output */
  .gp { color: $base0A } /* Generic.Prompt */
  .gs { color: $base0A; font-weight: bold } /* Generic.Strong */
  .gu { color: $orange } /* Generic.Subheading */
  .gt { color: $base0A } /* Generic.Traceback */
  .kc { color: $orange } /* Keyword.Constant */
  .kd { color: $blue } /* Keyword.Declaration */
  .kn { color: $green } /* Keyword.Namespace */
  .kp { color: $green } /* Keyword.Pseudo */
  .kr { color: $blue } /* Keyword.Reserved */
  .kt { color: $red } /* Keyword.Type */
  .ld { color: $base0A } /* Literal.Date */
  .m { color: $cyan } /* Literal.Number */
  .s { color: $cyan } /* Literal.String */
  .na { color: $base0A } /* Name.Attribute */
  .nb { color: $yellow } /* Name.Builtin */
  .nc { color: $blue } /* Name.Class */
  .no { color: $orange } /* Name.Constant */
  .nd { color: $blue } /* Name.Decorator */
  .ni { color: $orange } /* Name.Entity */
  .ne { color: $orange } /* Name.Exception */
  .nf { color: $blue } /* Name.Function */
  .nl { color: $base0A } /* Name.Label */
  .nn { color: $base0A } /* Name.Namespace */
  .nx { color: $base0A } /* Name.Other */
  .py { color: $base0A } /* Name.Property */
  .nt { color: $blue } /* Name.Tag */
  .nv { color: $blue } /* Name.Variable */
  .ow { color: $green } /* Operator.Word */
  .w { color: $base0A } /* Text.Whitespace */
  .mf { color: $cyan } /* Literal.Number.Float */
  .mh { color: $cyan } /* Literal.Number.Hex */
  .mi { color: $cyan } /* Literal.Number.Integer */
  .mo { color: $cyan } /* Literal.Number.Oct */
  .sb { color: $base01 } /* Literal.String.Backtick */
  .sc { color: $cyan } /* Literal.String.Char */
  .sd { color: $base0A } /* Literal.String.Doc */
  .s2 { color: $cyan } /* Literal.String.Double */
  .se { color: $orange } /* Literal.String.Escape */
  .sh { color: $base0A } /* Literal.String.Heredoc */
  .si { color: $cyan } /* Literal.String.Interpol */
  .sx { color: $cyan } /* Literal.String.Other */
  .sr { color: $red } /* Literal.String.Regex */
  .s1 { color: $cyan } /* Literal.String.Single */
  .ss { color: $cyan } /* Literal.String.Symbol */
  .bp { color: $blue } /* Name.Builtin.Pseudo */
  .vc { color: $blue } /* Name.Variable.Class */
  .vg { color: $blue } /* Name.Variable.Global */
  .vi { color: $blue } /* Name.Variable.Instance */
  .il { color: $cyan } /* Literal.Number.Integer.Long */
}
